import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image"
import styles from './services.module.scss';

const Services = () => (
	<div>
		<section className="section is-medium">
			<div className="container">
                <h1 className="title is-uppercase has-margin-bottom-1">El nostres <span className="has-text-danger">serveis</span></h1>
				<div className="columns is-multiline">
				<StaticQuery
                        query={graphql`
                            query {
                                allStrapiServices(
                                        sort: { fields: [order], order: ASC }
                                    ) {
                                    edges {
                                        node {								
                                            name
                                            slug
                                            summary
                                            image {
                                                childImageSharp {
                                                    fluid(maxWidth: 600, maxHeight: 200, quality: 90) {
                                                        ...GatsbyImageSharpFluid
                                                    }
                                                }
                                            }                                        
                                        }
                                    }
                                }
                            }
                        `}
                        render={data =>
                            data.allStrapiServices.edges.map((service, i) => {
                                return (
                                    <div key={i} className="column is-one-third">
                                        <div className={`box is-radiusless ${styles.servicebox}`}>
                                            {service.node.image ? <Img className={styles.headerImage} fluid={service.node.image.childImageSharp.fluid}></Img> : ''}
                                            <h2>{service.node.name}</h2>
                                            <p className="">{service.node.summary}</p>
                                            <Link to={`/serveis/${service.node.slug}`}>
                                            </Link>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    />
				</div>
			</div>
		</section>
	</div>
);

export default Services;
