import React from 'react';

import Layout from '../components/layout';
import Hero from '../components/hero';
import BannerContact from '../components/BannerContact';
import Services from '../components/services';

const IndexPage = () => (
    <Layout hideNavbar={true}>
        <Hero />
        <BannerContact />
        <Services />
    </Layout>
);

export default IndexPage;