import React from 'react';
import logo from '../images/img_logo.png';
import factory from '../images/img_factorytrees.png';
import './hero.scss';
import Navbar from './navbar';

const Hero = ({ children }) => (
	<section className="hero is-home-hero">
		<Navbar showLogo={false} />
		<div className="hero-body">
			<div className="container">
				<img src={logo} alt="Axioma"></img>
				<h1 className="title">
					CONSULTORÍA LÍDER EN ACÚSTICA
      			</h1>
				<h2 className="subtitle">
					PER LA INDÚSTRIA I EL MEDI AMBIENT
      			</h2>
				<div className="image">
					<img src={factory} alt="factory"></img>
				</div>
			</div>
		</div>
	</section>
);

export default Hero;
