import React from 'react';
import './hero.scss';
import { Link } from 'gatsby';

const BannerContact = () => (
    <section className="hero is-primary">
        <div className="hero-body hero-banner">
            <div className="container level">
                <div className="level-left">
                    <h2 class="subtitle">
                        BUSQUES UNA CONSULTORÍA ACÚSTICA DE QUALITAT?
                    </h2>
                </div>
                <div className="level-right">   
                    <a href="mailto:info@axioma-acustic.com" className="button is-danger">CONTACTE</a>
                </div>
            </div>
        </div>
    </section>
);

export default BannerContact;
